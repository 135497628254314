import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDisconnect } from "@thirdweb-dev/react";

export default function SignoutPage() {
    const navigate = useNavigate();
    const disconnect = useDisconnect();

    useEffect(() => {
        walletLogout();
    }, []);

    const walletLogout = async () => {
        try {
            await disconnect();
        } catch (error) {
            console.error("Error in disconnecting wallet", error);
        }
        localStorage.clear();
        sessionStorage.clear();
        // navigate("/signin"); // go to signin page
        // await disconnectWallet();
        window.location = "/signin";
        // navigate("/signin");
    };

    return null;
}
