import React from "react";
import Typography from "../../../components/Typography/Typography";
import Button from "../../../components/Button/Button";
import Loading from "../../../components/LoadingSkeleton/Loading";
import { BasicTable } from "../../../components/tables";
import { useNavigate } from "react-router-dom";
import "./tradeFiOverview.scss";

const PoolList = ({
    isLoading,
    tableData,
    handleOnViewPoolLinkClick,
    handleCreateNewPool,
}) => {
    const navigate = useNavigate();
    const handleViewButtonClick = (id) => {
        navigate(`/trade-finance-app/overview/detail`);
    };

    return (
        <div className="row mt-4">
            <div className="col-12">
                {isLoading ? (
                    <Loading />
                ) : tableData?.length > 0 ? (
                    <BasicTable
                        data={tableData}
                        columns={[
                            {
                                header: "Name",
                                accessor: "name",
                            },
                            {
                                header: "APY",
                                accessor: "apy",
                                Cell: ({ value }) => {
                                    return (
                                        <div className="apy-component">
                                            {value}
                                        </div>
                                    );
                                },
                            },
                            {
                                header: "TVL",
                                accessor: "tvl",
                                Cell: ({ value }) => {
                                    return (
                                        <div className="tvl-component d-flex align-items-center">
                                            <span className="tvl-label">
                                                {value}
                                            </span>
                                        </div>
                                    );
                                },
                            },
                            {
                                header: "Risk Rating",
                                accessor: "riskRating",
                                Cell: ({ value }) => {
                                    const riskPercentage = value * 10; // Assuming value is a rating from 1 to 10
                                    return (
                                        <div className="risk-rating-component">
                                            <div className="progress risk-rating-progress">
                                                <div
                                                    className="progress-bar"
                                                    role="progressbar"
                                                    style={{
                                                        width: `${riskPercentage}%`,
                                                    }}
                                                    aria-valuenow={
                                                        riskPercentage
                                                    }
                                                    aria-valuemin="0"
                                                    aria-valuemax="100"
                                                />
                                            </div>
                                            <span>{value}</span>
                                        </div>
                                    );
                                },
                            },
                            {
                                header: "Actions",
                                accessor: "actions",
                                Cell: ({ row: { original } }) => (
                                    <div
                                        className="link-button"
                                        onClick={() =>
                                            handleViewButtonClick(original.id)
                                        }
                                    >
                                        View
                                    </div>
                                ),
                            },
                        ]}
                        isSortable={false}
                        isPaginated={tableData?.length > 5}
                        pageSize={5}
                        currentPage={0}
                        showCheckbox={false}
                    />
                ) : (
                    <div className="d-flex flex-column align-items-center text-center pt-3">
                        <img
                            src="/images/placeholderImage.svg"
                            alt="Placeholder"
                        />
                        <Typography variant="h3" className="pt-3">
                            Start Your First Pool
                        </Typography>
                        <Typography variant="body1" className="pt-2">
                            You haven't started any pools yet. Click the button
                            below to start your first trade finance pool.
                        </Typography>
                        <Button
                            className="start-pool-button"
                            fullWidth={false}
                            onClick={handleCreateNewPool}
                        >
                            Start Pool
                        </Button>
                    </div>
                )}
            </div>
        </div>
    );
};

export default PoolList;
