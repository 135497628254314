import React from "react";
import "./loadingSkeleton.scss";

export default function ProjectTabLoadingSkeleton() {
    return (
        <div className="container-fluid project-container h-100 min-vh-100 w-100 m-0 p-0 mb-3">
            <div className="text-container">
                <div className="ps-4 pt-4 m-4 skeleton-title"></div>
                <div className="d-flex justify-content-center flex-wrap flex-md-nowrap align-items-center m-4 pt-3 pb-2 mb-3 d-md-flex justify-content-md-between">
                    <div className="d-flex align-items-center flex-column flex-md-row">
                        <div className="ps-md-4 ps-1 skeleton-h1"></div>
                    </div>
                    <div className="me-0 me-md-2 mb-2 mb-md-0 p-md-0 pt-3">
                        <div className="skeleton-button"></div>
                    </div>
                </div>
            </div>
            <div className="mx-3 row d-flex flex-wrap mt-4">
                <div className="col-12 col-md-12">
                    <div className="skeleton-table">
                        {[...Array(5)].map((_, i) => (
                            <div key={i} className="skeleton-row">
                                <div className="skeleton-cell"></div>
                                <div className="skeleton-cell"></div>
                                <div className="skeleton-cell"></div>
                                <div className="skeleton-cell"></div>
                                <div className="skeleton-cell"></div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}
