import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReauth } from './baseQueries';


export const tenantAPI = createApi({
    reducerPath: 'tenantAPI',
    baseQuery: baseQueryWithReauth,
    tagTypes: ['Profile'],
    endpoints: (builder) => ({
        getTenant: builder.query({
            query: () => ({
                url: '/api/tenants/',
                method: 'GET',
            }),
        }),
        createTenant: builder.mutation({
            query: ({ first_name, last_name, name, job_role, employee_count, industry }) => ({
                url: '/api/tenants/',
                method: 'POST',
                body: {
                    first_name,
                    last_name,
                    name,
                    job_role,
                    employee_count,
                    industry
                },
            }),
        }),
        getAllUsers: builder.query({
            query: () => ({
                url: '/api/tenants/users/',
                method: 'GET',
            }),
            invalidatesTags: ['Profile']
        }),

    }),
});

export const { useGetTenantQuery, useCreateTenantMutation, useGetAllUsersQuery } = tenantAPI;