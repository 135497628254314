import { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { setProjectDetails } from "../../services/projectDetailsSlice";
import { useGetProjectDetailQuery } from "../../services/projectService";
import ConfigurationTab from "./ConfigurationTab/ConfigurationTab";
import RecordsTab from "./RecordTab/RecordTab";
import { tableData } from "./data";
import "./project.scss";
import TraceTab from "./TraceTab/TraceTab";
import {
    ProjectTemplates,
    getProjectTemplateFromAPI,
} from "../../utils/projectUtils";
import TransferTab from "./TransferTab/TransferTab";
import SidebarLayout from "../../components/Sidebar/SidebarLayout";
import Settings from "../taas/Admin/SettingsTab/SettingsTab";
import BackersTab from "../taas/Admin/BackersTab/BackersTab";
import DashboardTab from "../taas/Admin/DashboardTab/DashboardTab";
import {
    useCreateProjectGoLiveMutation,
    useGetAssetQuery,
    useGetCustomerPortalThemeSettingQuery,
    useGetTokenizationQuery,
    useStopProjectGoLiveMutation,
} from "../../services/taasService";
import { toast } from "react-toastify";
import { useModal } from "../../hooks/modalsHooks";
import Loading from "../../components/LoadingSkeleton/Loading";
import { logEvent } from "../../utils/amplitudeUtlis";

export default function ProjectDetailPage() {
    const { id } = useParams();
    const memoizedProjectId = useMemo(() => id, [id]);
    const dispatch = useDispatch();
    const { showModal } = useModal();
    const { hideModal } = useModal();
    const {
        data: project,
        error,
        isLoading,
    } = useGetProjectDetailQuery(memoizedProjectId);

    const projectTemplate = project?.data.template
        ? getProjectTemplateFromAPI(project.data.template)
        : null;

    const { data: tokenizationResponse } = useGetTokenizationQuery(
        {
            projectId: memoizedProjectId,
        },
        {
            skip: projectTemplate !== "BAO",
        }
    );

    const { data: assetResponse } = useGetAssetQuery(
        {
            projectId: memoizedProjectId,
            assetId: tokenizationResponse?.data?.root_asset,
        },
        {
            skip:
                tokenizationResponse?.data.root_asset == null &&
                projectTemplate !== "BAO" &&
                !tokenizationResponse &&
                !tokenizationResponse?.data?.root_asset,
        }
    );

    const { data: themeResponse, isLoading: isThemeLoading } =
        useGetCustomerPortalThemeSettingQuery({
            projectId: id,
        });

    const navigate = useNavigate();

    const [isGoLiveLoading, setIsGoLiveLoading] = useState(false);

    const [goLive] = useCreateProjectGoLiveMutation({
        skip: projectTemplate !== "BAO",
    });

    const [stopGoLive] = useStopProjectGoLiveMutation({
        skip: projectTemplate !== "BAO",
    });

    const handleContinue = async () => {
        hideModal();
        navigate(`/overview/${id}/settings`);
        window.location.reload();
    };

    const handleModalClose = () => {
        hideModal();
    };

    const handleStopFunding = async () => {
        logEvent({
            eventName: "taas_stop_fund_raise_initiated",
            eventProperties: {
                projectId: id,
            },
        });
        try {
            const stopGoLiveResponse = await stopGoLive({ projectId: id });
            if (stopGoLiveResponse?.data) {
                hideModal();
                navigate(`/overview/${id}/settings`);
                toast("Project is stopped");
            } else {
                hideModal();
                toast.error(stopGoLiveResponse.error?.data?.errors[0]);
            }
        } catch (error) {
            toast.error(error);
        } finally {
            setIsGoLiveLoading(false);
        }
    };

    const handleStopFundRaise = async () => {
        logEvent({
            eventName: "taas_initialization_of_stop_fund_raise",
            eventProperties: {
                projectId: id,
            },
        });
        setIsGoLiveLoading(true);
        showModal({
            modalType: "PopUpModal",
            modalProps: {
                isOpen: true,
                projectId: id,
                type: "settings",
                title: "Are you sure? Stop Fund Raise",
                message:
                    "Are you sure you want to stop the fund raise? This action cannot be undone.",
                handleContinue: handleStopFunding,
                handleClose: handleModalClose,
            },
        });
    };

    const handleStartFundRaise = async () => {
        logEvent({
            eventName: "taas_initialization_of_start_fund_raise",
            eventProperties: {
                projectId: id,
            },
        });
        setIsGoLiveLoading(true);
        try {
            const goLiveResponse = await goLive({ projectId: id });
            if (goLiveResponse?.data) {
                toast("Project is on Live");
                navigate(`/token-app/${id}`);
            } else {
                if (
                    goLiveResponse.error?.data?.errors[0] ===
                    "default stripe account not set for project"
                ) {
                    showModal({
                        modalType: "PopUpModal",
                        modalProps: {
                            isOpen: true,
                            projectId: id,
                            type: "settings",
                            title: "Set Default Stripe Account",
                            message:
                                "Default stripe account not set for project. Please set the default account.",
                            handleContinue: handleContinue,
                            handleClose: handleModalClose,
                        },
                    });
                } else {
                    toast.error(goLiveResponse.error?.data?.errors[0]);
                }
            }

            logEvent({
                eventName: "taas_start_fund_raise_successful",
                eventProperties: {
                    projectId: id,
                },
            });
        } catch (error) {
            logEvent({
                eventName: "tass_start_fund_raise_failed",
                eventProperties: {
                    errorMessage: error,
                },
            });
            toast.error(error);
        } finally {
            setIsGoLiveLoading(false);
        }
    };

    const tabsData = useMemo(() => {
        if (projectTemplate === ProjectTemplates.SUPPLY_CHAIN) {
            return [
                {
                    name: "Dashboard",
                    component: (
                        <div className="px-4">
                            <DashboardTab />
                        </div>
                    ),
                    path: "dashboard",
                    icon: "bi bi-speedometer",
                },
                {
                    name: "Records",
                    component: (
                        <div className="px-4">
                            <RecordsTab tableData={tableData} />
                        </div>
                    ),
                    path: "records",
                    icon: "bi bi-card-checklist",
                },
                {
                    name: "Transfer",
                    component: (
                        <div className="px-4">
                            <TransferTab />
                        </div>
                    ),
                    path: "transfer",
                    icon: "bi bi-truck-front",
                },
                {
                    name: "Configuration",
                    component: (
                        <div className="px-4">
                            <ConfigurationTab
                                projectType={project?.data.template}
                            />
                        </div>
                    ),
                    path: "configuration",
                    icon: "bi bi-wrench-adjustable-circle",
                },
                {
                    name: "Trace Product",
                    component: (
                        <div className="px-4">
                            <TraceTab />
                        </div>
                    ),
                    path: "trace",
                    icon: "bi bi-exclamation-square",
                },
            ];
        }

        if (projectTemplate === ProjectTemplates.TOKENIZATION) {
            return [
                {
                    name: "Dashboard",
                    component: (
                        <div className="px-4">
                            <DashboardTab />
                        </div>
                    ),
                    path: "dashboard",
                    icon: "bi bi-speedometer2",
                },
                {
                    name: "Project Details",
                    component: (
                        <div className="px-4">
                            <ConfigurationTab
                                projectType={project?.data.template}
                            />
                        </div>
                    ),
                    path: "configuration",
                    icon: "bi bi-info-circle",
                },
                {
                    name: "Backers",
                    component: (
                        <div className="px-4">
                            <BackersTab />
                        </div>
                    ),
                    path: "backers",
                    icon: "bi bi-skip-backward",
                },
                {
                    name: "Settings",
                    component: (
                        <div className="px-4">
                            <Settings />
                        </div>
                    ),
                    path: "settings",
                    icon: "bi bi-gear",
                },
            ];
        }

        return [];
    }, [project]);

    useEffect(() => {
        if (project) {
            dispatch(setProjectDetails(project.data));
        }
    }, [project, dispatch]);

    if (error) {
        return <div>Error: {error.message}</div>;
    }

    if (isLoading) {
        return (
            <div
                className="d-flex justify-content-center align-items-center"
                style={{ height: "100vh" }}
            >
                <Loading />
            </div>
        );
    }
    return (
        <SidebarLayout
            pageData={tabsData}
            defaultPage="configuration"
            projectData={project}
            fundRaise={
                assetResponse?.data?.is_live
                    ? handleStopFundRaise
                    : handleStartFundRaise
            }
            fundingLoading={isGoLiveLoading}
            assetResponse={assetResponse}
            brandLogo={themeResponse?.data[0]?.logo?.file}
        />
    );
}
