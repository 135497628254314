/*
Modals can be stackable.
When hiding stacked modals the top-most one will go out first.(LIFO)
Show/hide functionality will be handled via a custom hook. (check modalHooks)
Modals will blur the backgrounds. Clicking on the background won't close the modal.

Edit below component to change these beaviours. Make sure to consult an SSE or above rank before making changes.
*/

import { useContext } from "react";
import { ModalContext } from "../../providers/ModalProvider";

import ExampleModalOne from "../popupModel/ExampleModalOne";
import CreateProjectModal from "../popupModel/CreateProjectModal";
import SuccessModal from "../popupModel/SuccessModal";
import EditModal from "../popupModel/EditModal";
import InviteUserModal from "../popupModel/InviteUserModal";
import ContactUsModal from "../popupModel/ContactUsModal";
import TraceAssetModal from "../popupModel/TraceAssetModal";

import "./modal-container.scss";
import PopUpModal from "../popupModel/PopUpModal";
import CheckoutModal from "../taas/customer/Popupmodal/CheckoutModal";

// register modals here.
const modalContent = {
    ExampleModalOne,
    CreateProjectModal,
    SuccessModal,
    EditModal,
    InviteUserModal,
    ContactUsModal,
    CheckoutModal,
    TraceAssetModal,
    PopUpModal,
};

export default function ModalContainer() {
    const { modalProps, modalTypes, hideModal } = useContext(ModalContext);

    const handleClose = async () => {
        // hide current modal
        await hideModal();
    };

    return modalTypes.map((modalType) => {
        const CurrModalContent = modalContent[modalType];
        const currModalProps = { ...modalProps[modalType] };
        if (currModalProps && !currModalProps.closeModal) {
            currModalProps.closeModal = handleClose;
        }

        return (
            <div
                key={modalType}
                className="modal-container"
                id="exampleModal"
                tabIndex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="d-flex flex-column align-items-center pt-5">
                    <div className="d-flex justify-content-center sticky-sm-top top-0 w-100">
                        <div className="btn-modal-close" onClick={handleClose}>
                            <img
                                src="/icons/close-circle.png"
                                width="35px"
                                height="35px"
                                alt=""
                            />
                        </div>
                    </div>
                    <CurrModalContent {...currModalProps} />
                </div>
            </div>
        );
    });
}
