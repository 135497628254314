import React, { useState, useMemo, useEffect } from "react";
import Typography from "../../../../components/Typography/Typography";
import Button from "../../../../components/Button/Button";
import { useNavigate, useParams } from "react-router-dom";
import { Form, Select, TextField } from "../../../../components/forms";
import {
    useGetAssetsTypeQuery,
    useGetNodesListQuery,
    useGetOneAssetsTypeQuery,
    useUpdateAssetTypeMutation,
} from "../../../../services/supplyChainService";

import "./asset.scss";
import { string_to_snake_case } from "../../../../utils/projectUtils";
import { toast } from "react-toastify";
import { logEvent } from "../../../../utils/amplitudeUtlis";

const formConfigOptions = [
    {
        label: "Text Input",
        value: "text",
    },
    {
        label: "Date Input",
        value: "date",
    },
];

function FormComponent({
    fieldName,
    fieldLabel,
    fieldType,
    fieldIndex,
    onRemoveField,
    onChangeProp,
}) {
    return (
        <div className="add-container">
            <div className="d-flex justify-content-between m-3 add-header">
                <div className="add-title">Form field - #{fieldIndex}</div>
                <div className="add-close" onClick={onRemoveField}>
                    X Remove
                </div>
            </div>

            <div className="m-3">
                <TextField
                    name="form_config_label"
                    label="Field Label"
                    value={fieldLabel}
                    onChange={(e) => onChangeProp("label", e.target.value)}
                />
            </div>

            <div className="m-3">
                <TextField
                    name="form_config_name"
                    label="Field Name"
                    value={fieldName}
                    disabled={true}
                    onChange={(e) => onChangeProp("name", e.target.value)}
                />
            </div>

            <div className="m-3">
                <Select
                    options={formConfigOptions}
                    name="form_config_type"
                    label="Field Type"
                    value={fieldType}
                    onChange={(e) => onChangeProp("type", e.target.value)}
                />
            </div>
        </div>
    );
}

export default function EditAssetPage() {
    const navigate = useNavigate();
    const { id, assetTypeId } = useParams();
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);

    const { data: selectedAssetTypeResponse } = useGetOneAssetsTypeQuery(
        { id, assetTypeId },
        { skip: !id }
    );

    const { data: assetTypesResponse } = useGetAssetsTypeQuery(id, {
        skip: !id,
    });

    const { data: nodesResponse, isLoading: isLoadingNodes } =
        useGetNodesListQuery(id, { skip: !id });

    const assetTypes = useMemo(() => {
        if (!assetTypesResponse?.data) {
            return [];
        }
        let atypes = [];
        for (var at of assetTypesResponse?.data) {
            if (at.id !== assetTypeId) {
                atypes.push({
                    label: at.type_name,
                    value: at.type_name,
                });
            }
        }
        return atypes;
    }, [assetTypesResponse]);

    const _formFields = useMemo(() => {
        if (!selectedAssetTypeResponse?.data?.form_config?.fields) {
            return [];
        }

        return selectedAssetTypeResponse?.data.form_config?.fields;
    }, [selectedAssetTypeResponse]);

    const [formFields, setFormFields] = useState([]);

    const dependencyConfig = selectedAssetTypeResponse?.data.dependency_config
        ?.dependencies
        ? selectedAssetTypeResponse?.data.dependency_config.dependencies[0]
        : null;

    let initialValues = {
        type_name: selectedAssetTypeResponse?.data.type_name,
        dependency: dependencyConfig?.type,
    };

    const [updateAssetType] = useUpdateAssetTypeMutation();

    useEffect(() => {
        setFormFields(_formFields);
    }, [_formFields]);

    const handleCloseClick = () => {
        navigate(`/overview/${id}/`);
        logEvent({
            eventName: "cancel_button_clicked",
            eventProperties: {
                page_name: "Edit Asset Page",
            },
        });
    };

    const handleAddAsset = async (values) => {
        setIsLoading(true);

        let _dependencyConfig = {
            dependencies: [],
        };

        if (values.dependency) {
            _dependencyConfig.dependencies.push({
                type: values.dependency,
                label: values.dependency,
            });
        }

        try {
            const updateAssetResponse = await updateAssetType({
                id: id,
                assetTypeId: assetTypeId,
                form_config: {
                    fields: formFields,
                },
                dependency_config: _dependencyConfig,
            });

            if (updateAssetResponse?.data.errors) {
                logEvent({
                    eventName: "record_type_update_error",
                    eventProperties: {
                        errorMessage: updateAssetResponse?.data.errors,
                    },
                });
                throw "Error in updating record type.";
            }
            logEvent({
                eventName: "record_type_updated",
                eventProperties: {
                    assetTypeId: assetTypeId,
                },
            });
            toast("Record type updated.");
            navigate(`/overview/${id}/configuration`);
        } catch (error) {
            toast("Error in updating record type");
            logEvent({
                eventName: "record_type_update_error",
                eventProperties: {
                    errorMessage: error,
                },
            });
        } finally {
            setIsLoading(false);
        }
    };

    const handleAddFormField = () => {
        setFormFields([
            ...formFields,
            {
                label: "New field",
                name: "newfield",
                type: "",
            },
        ]);
    };

    const handleRemoveFormField = (index) => {
        setFormFields(formFields.filter((_, i) => i !== index));
    };

    const handleOnChangeFormFieldProp = (index, field, value) => {
        setFormFields(
            formFields.map((f, i) => {
                if (i !== index) {
                    return f;
                }

                if (field === "label") {
                    return {
                        ...f,
                        label: value,
                        name: string_to_snake_case(value),
                    };
                } else {
                    return {
                        ...f,
                        [field]: value,
                    };
                }
            })
        );
    };

    return (
        <div className="project-container h-100 min-vh-100 w-100 px-4">
            <div className="pt-3">
                <Typography variant="body1">
                    Overview / Edit record type
                </Typography>

                <div className="d-flex justify-content-center flex-wrap flex-md-nowrap align-items-center d-md-flex justify-content-md-between">
                    <div className="d-flex align-items-center flex-column flex-md-row">
                        <Typography variant="h1">Edit record type</Typography>
                    </div>
                    <div className="me-0 mb-2 mb-md-0 p-md-0 p-4 pt-3">
                        <Button
                            type="button"
                            variant="secondary"
                            fullWidth={false}
                            onClick={handleCloseClick}
                        >
                            Cancel
                        </Button>
                    </div>
                </div>
                <div className="mt-4 divider-line"></div>
            </div>
            <div className="container col-xxl-8 px-4 pt-4">
                <div className="row align-items-center justify-content-center d-flex flex-row">
                    <Form
                        initialValues={initialValues}
                        onSubmit={handleAddAsset}
                        enableReinitialize={true}
                    >
                        <div className="m-3">
                            <TextField
                                name="type_name"
                                label="Record Type"
                                disabled={true}
                            />
                        </div>

                        <div className="m-3">
                            <Select
                                options={assetTypes}
                                name="dependency"
                                label="Linked Record Type (Keep empty if no links)"
                            />
                        </div>

                        <div className="m-3">
                            {formFields.map((ff, idx) => (
                                <FormComponent
                                    fieldName={ff["name"]}
                                    fieldLabel={ff["label"]}
                                    fieldType={ff["type"]}
                                    fieldIndex={idx + 1}
                                    onRemoveField={() =>
                                        handleRemoveFormField(idx)
                                    }
                                    onChangeProp={(field, value) =>
                                        handleOnChangeFormFieldProp(
                                            idx,
                                            field,
                                            value
                                        )
                                    }
                                />
                            ))}
                        </div>

                        <div
                            className="m-3 add-another-node"
                            onClick={handleAddFormField}
                        >
                            + Add form field
                        </div>

                        <div className="m-3">
                            <Button
                                type="submit"
                                variant="primary"
                                fullWidth={false}
                                isLoading={isLoading}
                            >
                                Save
                            </Button>
                        </div>
                    </Form>
                </div>
            </div>
        </div>
    );
}
