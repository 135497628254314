import React from "react";
import { Icon } from "../../../components/Icon";
import Typography from "../../../components/Typography/Typography";
import Button from "../../../components/Button/Button";
import { BasicTable } from "../../../components/tables";
import ProjectTabLoadingSkeleton from "../../../components/LoadingSkeleton/ProjectTabLoadingSkeleton";
import { useModal } from "../../../hooks/modalsHooks";
import {
    useGetAllTransferQuery,
    useGetAssetsListQuery,
    useGetNodesListQuery,
} from "../../../services/supplyChainService";
import { useNavigate, useParams } from "react-router-dom";
import { useGetProjectDetailQuery } from "../../../services/projectService";

function TransferTab() {
    const navigate = useNavigate();
    const { showModal } = useModal();
    const { id } = useParams();
    const { data: project, error, isLoading } = useGetProjectDetailQuery(id);
    const {
        data: transferResponse,
        isLoading: isLoadingTransfers,
        refetch,
    } = useGetAllTransferQuery(id, { skip: !id });

    const { data: assetsResponse, isLoading: isLoadingAssets } =
        useGetAssetsListQuery({ projectId: id, skip: !id });
    const isButtonDisabled = !assetsResponse?.data?.length;

    const transferList = transferResponse?.data;

    const handleAddRecords = () => {
        navigate(`/overview/${id}/addTransfer`);
    };

    const handleTraceClick = (assetId) => {
        showModal({
            modalType: "TraceAssetModal",
            modalProps: {
                assetLink: `/app/${id}/trace/${assetId}`,
            },
        });
    };

    const transferData =
        transferList?.map((transfer) => {
            return {
                fromNode: transfer.from_node.name,
                toNode: transfer.to_node.name,
                // transferContent: transfer.transfer_content,
                transferState: transfer.status,
                assetId: transfer.id,
                createdBy: transfer.owner.first_name,
                createdDate: transfer.created_at,
                action: "--",
            };
        }) || [];

    React.useEffect(() => {
        if (transferResponse) {
            refetch();
        }
    }, [transferResponse, refetch]);

    if (isLoadingTransfers) {
        return <ProjectTabLoadingSkeleton />;
    }

    return (
        <div className="row d-flex flex-wrap">
            <div className="col-12 col-md-12">
                <div className="d-flex flex-column flex-sm-row justify-content-md-start justify-content-center align-items-center pt-3 pb-2 mb-3">
                    <div className="d-flex align-items-center flex-column flex-sm-row">
                        <Typography variant="h3" className="m-0">
                            Transfer
                        </Typography>
                    </div>
                    <div className="me-0 mb-2 mb-sm-0 p-md-0 p-4 pt-3 pe-md-1 ms-sm-auto d-flex justify-content-center justify-content-sm-start">
                        <Button
                            className="text-truncate"
                            type="button"
                            variant="primary"
                            fullWidth={false}
                            onClick={handleAddRecords}
                            disabled={isButtonDisabled}
                        >
                            Initialize Transfer
                        </Button>
                    </div>
                </div>

                <BasicTable
                    data={transferData}
                    columns={[
                        {
                            header: "From Node",
                            accessor: "fromNode",
                        },
                        {
                            header: "To Node",
                            accessor: "toNode",
                        },
                        {
                            header: "Status",
                            accessor: "transferState",
                        },
                        {
                            header: "Created by",
                            accessor: "createdBy",
                        },
                        {
                            header: "Created date",
                            accessor: "createdDate",
                            Cell: ({ cell: { value } }) => {
                                const dateObject = new Date(value);
                                const formattedDate = dateObject
                                    .toLocaleDateString("en-US")
                                    .split("/")
                                    .join("-");
                                return <div>{formattedDate}</div>;
                            },
                        },
                        {
                            header: "",
                            accessor: "action",
                            Cell: ({ row }) => (
                                <div className="d-flex flex-row justify-content-end">
                                    <Icon
                                        src="/icons/routing.svg"
                                        className="mx-2"
                                        height={15}
                                        width={15}
                                        onClick={() => {
                                            handleTraceClick(
                                                row.original.assetId
                                            );
                                        }}
                                    />
                                    <Icon
                                        src="/icons/edit.svg"
                                        className="mx-2"
                                        height={15}
                                        width={15}
                                        onClick={() => {}}
                                    />
                                    <Icon
                                        src="/icons/archive.svg"
                                        className="mx-2"
                                        height={15}
                                        width={15}
                                        onClick={() => {}}
                                    />
                                </div>
                            ),
                        },
                    ]}
                    isSortable={false}
                    isPaginated={transferData?.length > 5}
                    pageSize={5}
                    currentPage={0}
                    showCheckBox={true}
                />
            </div>
        </div>
    );
}

export default TransferTab;
