import { useEffect } from "react";
import "./tradeFiOverview.scss";
import Typography from "../../../components/Typography/Typography";
import { useGetTradeFiOverviewQuery } from "../../../services/tradeFiService";
import { useModal } from "../../../hooks/modalsHooks";
import { logEvent } from "../../../utils/amplitudeUtlis";
import { useNavigate } from "react-router-dom";
import Loading from "../../../components/LoadingSkeleton/Loading";
import TradeFiSummary from "./TradeFiSummary";
import PoolSummary from "../tradeFiPoolDetailPage/PoolSummary";

import PoolList from "./PoolList";
import Button from "../../../components/tradeFinance/Button/Button";

export default function TradeFiOverviewPage() {
    const { data: overview, isLoading, refetch } = useGetTradeFiOverviewQuery();
    const data = overview?.data;
    const { showModal } = useModal();
    const navigate = useNavigate();

    const handleCreateNewPool = async () => {
        logEvent({
            eventName: "pool_creation_initiated",
            eventProperties: {},
        });
        showModal({
            modalType: "CreatePoolModal",
            modalProps: {
                poolType: "trade-fi",
            },
        });
    };

    const handleOnViewPoolLinkClick = (id) => {
        logEvent({
            eventName: "pool_view_button_clicked",
            eventProperties: {
                pool_name: data?.name,
                pool_type: data?.template,
            },
        });
        navigate(`/overview/${id}/configuration`);
    };

    const tableData = data?.pools.map((pool) => ({
        id: pool.pool_id,
        name:
            pool.pool_name.charAt(0).toUpperCase() +
            pool.pool_name.slice(1).toLowerCase(),
        apy: pool.pool_apy.toFixed(2) + "%",
        tvl: `$${pool.pool_tvl.toLocaleString()}`,
        riskRating: pool.pool_risk_rating || "NaN",
    }));

    return (
        <div className="container px-5 pt-2">
            <div className="row d-flex justify-content-center flex-wrap flex-md-nowrap align-items-center pt-3 justify-content-md-start">
                <div className="col text-center text-md-start">
                    <Typography variant="h1">Your Pools</Typography>
                </div>
                <div className="col d-flex align-items-center justify-content-center justify-content-md-end">
                    <Button
                        type="button"
                        variant="secondary"
                        className="new-pool-button"
                        fullWidth={false}
                        // onClick={handleCreateNewPool}
                    >
                        Create New Pool
                    </Button>
                </div>
            </div>
            <Typography variant="h3" className="pt-3">
                Total
            </Typography>
            <TradeFiSummary data={data} />
            <PoolList
                isLoading={isLoading}
                tableData={tableData}
                handleOnViewPoolLinkClick={handleOnViewPoolLinkClick}
                handleCreateNewPool={handleCreateNewPool}
            />
        </div>
    );
}
