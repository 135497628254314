// Dummy data to be returned by the query
const dummyData = {
    data: {
        active_loans: "$0",
        total_loss_rate: "0%",
        total_loans_repaid: "$0",
        interest_repaid: "$0",
        pools: [
            {
                pool_id: "1",
                pool_name: "Pool 1",
                pool_apy: 10.34,
                pool_tvl: 12312232,
                pool_risk_rating: "NaN",
            },
            {
                pool_id: "2",
                pool_name: "Pool 2",
                pool_apy: 9.56,
                pool_tvl: 9345123,
                pool_risk_rating: "NaN",
            },
            {
                pool_id: "3",
                pool_name: "Pool 3",
                pool_apy: 8.92,
                pool_tvl: 14213123,
                pool_risk_rating: "NaN",
            },
            {
                pool_id: "4",
                pool_name: "Pool 4",
                pool_apy: 11.23,
                pool_tvl: 7123123,
                pool_risk_rating: "NaN",
            },
            {
                pool_id: "5",
                pool_name: "Pool 5",
                pool_apy: 9.87,
                pool_tvl: 11234567,
                pool_risk_rating: "NaN",
            },
        ],
    },
};

const empty = {
    data: {
        active_loans: "$0",
        total_loss_rate: "0%",
        total_loans_repaid: "$0",
        interest_repaid: "$0",
        pools: [],
    },
}

// Simplified function to return dummy data
export function useGetTradeFiOverviewQuery() {
    return {
        data: dummyData,
        isLoading: false,
        refetch: () => {},
    };
}
