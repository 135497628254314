export const ProjectTemplates = {
    "SUPPLY_CHAIN": "SUPPLY_CHAIN",
    "TOKENIZATION": "BAO"
}

export function getProjectTemplateFromAPI(apiTemaplte){
    switch(apiTemaplte){
        case "SUPPLY_CHAIN":
            return ProjectTemplates.SUPPLY_CHAIN;
        
        case "BAO":
            return ProjectTemplates.TOKENIZATION;
        
        default:
            return null;
    }
}

export function getProjectTemplateName(template){
    switch(template){
        case "SUPPLY_CHAIN":
            return "Supply chain traceability";
        
        case "BAO":
            return "Tokenization";
        
        default:
            return "Other";
    }
}

export function string_to_snake_case(str) { 
    return str && str.match( 
/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g) 
        .map(s => s.toLowerCase()) 
        .join('_'); 
}