import React from "react";
import Typography from "../../../components/Typography/Typography";
import Button from "../../../components/Button/Button";
import BasicInfo from "./BasicInfo";
import BarChartComponent from "../../../components/Chart/BarChart";
import { BasicTable } from "../../../components/tables";
import "./tradeFiPoolDetail.scss";
import RepaymentList from "./RepaymentList";

import BorrowersTab from "./BorrowersTab";
import LoanTab from "./LoanTab";
import Tab from "../../../components/tradeFinance/Tab/Tab";
import VerticalStepper from "./RecentActivity";
import VerticalTimeline from "./RecentActivity";
import PoolSummary from "./PoolSummary";

const graphData = [
    { month: "Jan", value: 30 },
    { month: "Feb", value: 150 },
    { month: "Mar", value: 50 },
    { month: "Apr", value: 85 },
    { month: "May", value: 50 },
    { month: "Jun", value: 56 },
    { month: "Jul", value: 98 },
    { month: "Aug", value: 25 },
    { month: "Sep", value: 89 },
    { month: "Oct", value: 89 },
    { month: "Nov", value: 85 },
    { month: "Dec", value: 55 },
];

const repaymentData = [
    {
        status: "Paid",
        amount: "$100,000",
        date: "12/12/2022",
    },
    {
        status: "Paid",
        amount: "$50,000",
        date: "12/12/2022",
    },
    {
        status: "Paid",
        amount: "$100,000",
        date: "12/12/2022",
    },
    {
        status: "Paid",
        amount: "$50,000",
        date: "12/12/2022",
    },
    {
        status: "Paid",
        amount: "$100,000",
        date: "12/12/2022",
    },
];

const borrowersData = [
    {
        name: "John Doe",
        amount: "$100,000",
        interestRate: "5%",
        date: "12/12/2022",
        status: "Paid",
    },
    {
        name: "Jane Doe",
        amount: "$50,000",
        interestRate: "5%",
        date: "12/12/2022",
        status: "Paid",
    },
    {
        name: "John Doe",
        amount: "$100,000",
        interestRate: "5%",
        date: "12/12/2022",
        status: "Paid",
    },
    {
        name: "Jane Doe",
        amount: "$50,000",
        interestRate: "5%",
        date: "12/12/2022",
        status: "Paid",
    },
    {
        name: "John Doe",
        amount: "$100,000",
        interestRate: "5%",
        date: "12/12/2022",
        status: "Paid",
    },
];

const tabsData = [
    {
        path: "borrowers",
        name: "Borrowers",
        component: <BorrowersTab borrowersData={borrowersData} />,
    },
    {
        path: "load",
        name: "Loan",
        component: <LoanTab borrowersData={borrowersData} />,
    },
];

const timelineData = [
    {
        icon: "path/to/image1.jpg",
        title: "Creative Director",
        subtitle: "Miami, FL",
        content:
            "Creative Direction, User Experience, Visual Design, Project Management, Team Leading",
    },
    {
        icon: "path/to/image2.jpg",
        title: "Art Director",
        subtitle: "San Francisco, CA",
        content:
            "Creative Direction, User Experience, Visual Design, SEO, Online Marketing",
    },
    // Add more timeline items as needed
];

const TradeFiPoolDetailPage = () => {
    return (
        <div className="container px-5 pt-2">
            <div className="row d-flex justify-content-center flex-wrap flex-md-nowrap align-items-center pt-3 justify-content-md-start">
                <div className="col text-center text-md-start">
                    <Typography variant="h1">Pool1</Typography>
                </div>
                <div className="col d-flex align-items-center justify-content-center justify-content-md-end">
                    <Button type="button" variant="secondary" fullWidth={false}>
                        Back to dashboard
                    </Button>
                </div>
            </div>
            <BasicInfo
                description="Pool 1 is a high-yield lending pool with a low risk rating."
                totalValue="$12,312,232"
                apy="10.34%"
                riskRating="Low"
                liquidity="$1,234,567"
            />
            <div className="row pt-5">
                <div className="col-12 d-flex flex-wrap ">
                    <Typography variant="h3" className="m-0">
                        Overview
                    </Typography>
                </div>
                <PoolSummary
                    data={{
                        active_loans: "0%",
                        active_loans_percentage: "0",
                        total_loss_rate: "$0",
                        total_loss_percentage: "0",
                        total_loans_repaid: "0%",
                        total_loans_percentage: "0",
                    }}
                />
            </div>
            <div className="row pt-4">
                <div className="col-12 pt-4">
                    <div className="card chart-card">
                        <div className="card-body">
                            <Typography variant="h4" className="py-2">
                                Repayment Schedule
                            </Typography>
                            <Typography variant="h1" className="">
                                $10000
                            </Typography>
                            <BarChartComponent
                                data={graphData}
                                dataKey="value"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <RepaymentList data={repaymentData} />
            <div className="row py-4">
                <div className="col-12 d-flex flex-wrap ">
                    <Typography variant="h3" className="m-0">
                        Portfolio
                    </Typography>
                </div>
                <div className="col-12 pt-3">
                    <Tab tabsData={tabsData} tabType="pills" defaultTab={0} />
                </div>
            </div>
            {/* <div className="row pt-4">
                <div className="col-12 d-flex flex-wrap ">
                    <Typography variant="h3" className="m-0">
                        Recent Activity
                    </Typography>
                </div>
                <div className="col-12 pt-3">
                    <VerticalTimeline data={timelineData} />
                </div>
            </div> */}
        </div>
    );
};

export default TradeFiPoolDetailPage;
