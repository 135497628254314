import React, { useMemo } from "react";
import { usePagination, useSortBy, useTable, useRowSelect } from "react-table";
import "./table.scss";

/**
 * Table component wrapped around `react-table`.
 * @param {{
 * data: any[],
 * columns: any[],
 * isSortable?: boolean,
 * isPaginated?: boolean,
 * isChecked?: boolean,
 * pageSize?: number,
 * pageIndex?: number,
 * }} props
 * @returns {React.FunctionComponent}
 */
export default function BasicTable({
    data,
    columns,
    isSortable = false,
    isPaginated = false,
    pageSize = 10,
    currentPage = 0,
    showCheckBox = false,
}) {
    const IndeterminateCheckbox = React.forwardRef(
        ({ indeterminate, ...rest }, ref) => {
            const defaultRef = React.useRef();
            const resolvedRef = ref || defaultRef;

            React.useEffect(() => {
                resolvedRef.current.indeterminate = indeterminate;
            }, [resolvedRef, indeterminate]);

            return (
                <div className="m-2 align-middle">
                    <input
                        type="checkbox"
                        className="custom-control-input"
                        ref={resolvedRef}
                        {...rest}
                    />
                </div>
            );
        }
    );

    const tableColumns = useMemo(
        () =>
            columns.map((col) => ({
                ...col,
                Header: col.header,
            })),
        [columns]
    );

    const checkboxColumn = {
        id: "selection",
        Header: ({ getToggleAllRowsSelectedProps }) => (
            <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
        ),
        Cell: ({ row }) => (
            <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
        ),
    };

    if (showCheckBox) {
        tableColumns.unshift(checkboxColumn);
    }

    const pluginList = useMemo(() => {
        let list = [];

        if (isSortable) {
            list.push(useSortBy);
        }

        if (isPaginated) {
            list.push(usePagination);
        }
        list.push(useRowSelect);
        return list;
    }, [isPaginated, isSortable]); // Add an empty array as the second argument to useMemo

    const tableInstance = useTable(
        {
            columns: tableColumns,
            data: data,
            initialState: {
                pageSize: pageSize,
                pageIndex: currentPage,
            },
        },
        ...pluginList
    );

    const getHeaderProps = (tableInstance, column) => {
        if (isSortable) {
            return column.getHeaderProps(column.getSortByToggleProps());
        }

        return column.getHeaderProps();
    };

    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
        tableInstance;

    return (
        <div>
            <div className="table-responsive">
                <table
                    className="table table-borderless table-sm shadow-sm w-100 w-sm-auto"
                    {...getTableProps()}
                >
                    <thead className="m-1 table-header">
                        {headerGroups.map((headerGroup) => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map((column) => (
                                    <th
                                        className="align-middle ps-3"
                                        {...getHeaderProps(
                                            tableInstance,
                                            column
                                        )}
                                    >
                                        {column.render("Header")}
                                        {isSortable && (
                                            <span>
                                                {column.isSorted
                                                    ? column.isSortedDesc
                                                        ? " ˅"
                                                        : " ˄"
                                                    : ""}
                                            </span>
                                        )}
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {(isPaginated ? tableInstance.page : rows).map(
                            (row) => {
                                prepareRow(row);
                                return (
                                    <tr
                                        {...row.getRowProps()}
                                        className="table-row-border"
                                    >
                                        {row.cells.map((cell) => {
                                            return (
                                                <td
                                                    className="align-middle py-2 ps-3"
                                                    {...cell.getCellProps()}
                                                >
                                                    {cell.render("Cell")}
                                                </td>
                                            );
                                        })}
                                    </tr>
                                );
                            }
                        )}
                    </tbody>
                </table>
            </div>
            {isPaginated && (
                <div className="d-flex justify-content-md-end justify-content-center mb-2 mb-md-0 mt-5 table-pagination">
                    <nav>
                        <ul className="pagination">
                            <li
                                className={`m-1 page-item ${
                                    !tableInstance.canPreviousPage
                                        ? "disabled"
                                        : ""
                                }`}
                            >
                                <a
                                    className="page-navigator page-link previous-page"
                                    href="#"
                                    onClick={tableInstance.previousPage}
                                >
                                    <span className="m-1">{`<`}</span>
                                    Previous
                                </a>
                            </li>
                            {tableInstance.pageCount > 0 && (
                                <>
                                    {[...Array(tableInstance.pageCount)].map(
                                        (_, p) => (
                                            <li
                                                key={p}
                                                className={`m-1 page-item ${
                                                    p ===
                                                    tableInstance.state
                                                        .pageIndex
                                                        ? "active"
                                                        : ""
                                                }`}
                                            >
                                                <a
                                                    className="page-link page-number "
                                                    href="#"
                                                    onClick={() =>
                                                        tableInstance.gotoPage(
                                                            p
                                                        )
                                                    }
                                                >
                                                    {p + 1}
                                                </a>
                                            </li>
                                        )
                                    )}
                                </>
                            )}

                            <li
                                className={`m-1 page-item ${
                                    !tableInstance.canNextPage ? "disabled" : ""
                                }`}
                            >
                                <a
                                    className="page-navigator page-link next-page"
                                    href="#"
                                    onClick={tableInstance.nextPage}
                                >
                                    Next<span className="m-1">{`>`}</span>
                                </a>
                            </li>
                        </ul>
                    </nav>
                </div>
            )}
        </div>
    );
}
