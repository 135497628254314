import React from "react";
import { BasicTable } from "../../../components/tables";

const BorrowersTab = ({ borrowersData }) => {
    return (
        <div>
            <BasicTable
                data={borrowersData}
                columns={[
                    {
                        header: "Borrower Name",
                        accessor: "name",
                    },
                    {
                        header: "Portfolio share",
                        accessor: "interestRate",
                    },
                    {
                        header: "Amount",
                        accessor: "amount",
                    },
                ]}
                isSortable={false}
                isPaginated={borrowersData?.length > 5}
                pageSize={5}
                currentPage={0}
                showCheckbox={false}
            />
        </div>
    );
};

export default BorrowersTab;
