import React from "react";

import {
    Checkbox,
    FileField, Select,
    TextField
} from "../../../components/forms";
import TextArea from "../../../components/forms/TextArea";
import { useGetAssetsListQuery } from "../../../services/supplyChainService";
import { useParams } from "react-router-dom";

const RecordForm = ({
    fieldType,
    name,
    label,
    options = [],
    typeName = null,
    ...props
}) => {
    const { id } = useParams();

    const { data: assetResponse, isLoading: isLoadingAssets } =
        useGetAssetsListQuery({ projectId: id });

    const renderField = () => {
        switch (fieldType) {
            case "text":
                return <TextField name={name} label={label} />;
            case "number":
                return <TextField name={name} label={label} type="number" />;
            case "date":
                return <TextField name={name} label={label} type="date" />;
            case "dropdown":
                return (
                    <Select
                        name={name}
                        label={label}
                        options={options}
                        {...props}
                    />
                );
            case "file":
                return <FileField name={name} label={label} />;
            case "checkbox":
                return <Checkbox name={name} label={label} />;
            case "description":
                return <TextArea name={name} label={label} />;
            case "dependency":
                /**TODO: Change this to call the filter endpoint and get relevant assets */
                const filteredAssets = assetResponse?.data
                    .filter((asset) => asset.asset_type.type_name === typeName)
                    .map((asset) => ({
                        value: asset.asset_id,
                        label: asset.name,
                    }));

                return (
                    <Select
                        name={name}
                        label={label}
                        options={filteredAssets}
                        {...props}
                    />
                );

            // Add more cases as needed
            default:
                return <TextField name={name} label={label} />;
        }
    };

    return <div className="my-4">{renderField()}</div>;
};

export default RecordForm;
