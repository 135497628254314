import React from "react";
import { BasicTable } from "../../../components/tables";

const LoanTab = ({ borrowersData }) => {
    return (
        <div>
            <BasicTable
                data={borrowersData}
                columns={[
                    {
                        header: "Name",
                        accessor: "name",
                    },
                    {
                        header: "Amount",
                        accessor: "amount",
                    },
                    {
                        header: "Interest Rate",
                        accessor: "interestRate",
                    },

                    {
                        header: "Due Date",
                        accessor: "date",
                    },
                    {
                        header: "Status",
                        accessor: "status",
                        Cell: ({ cell: { value } }) => (
                            <div className="status-cell">{value}</div>
                        ),
                    },
                ]}
                isSortable={false}
                isPaginated={borrowersData?.length > 5}
                pageSize={5}
                currentPage={0}
                showCheckbox={false}
            />
        </div>
    );
};

export default LoanTab;
