import React from "react";
import { BasicTable } from "../../../components/tables";

const RepaymentList = ({ data }) => {
    return (
        <div className="row pt-4">
            <div className="col-12">
                <BasicTable
                    data={data}
                    columns={[
                        {
                            header: "Date",
                            accessor: "date",
                        },
                        {
                            header: "Repayment Amount",
                            accessor: "amount",
                        },
                        {
                            header: "Status",
                            accessor: "status",
                            Cell: ({ cell: { value } }) => (
                                <div className="status-cell">{value}</div>
                            ),
                        },
                    ]}
                    isSortable={false}
                    isPaginated={data?.length > 5}
                    pageSize={5}
                    currentPage={0}
                    showCheckbox={false}
                />
            </div>
        </div>
    );
};

export default RepaymentList;
