import React from "react";
import PropTypes from "prop-types";

const Button = ({ variant, onClick, children }) => {
    const buttonClass = `btn btn-${variant}`;
    return (
        <button className={buttonClass} onClick={onClick}>
            {children}
        </button>
    );
};

Button.propTypes = {
    variant: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    children: PropTypes.node.isRequired,
};

export default Button;
