import React from "react";
import TradeFiOverviewCard from "../../../components/tradeFinance/Card/tradeFiOverviewCard";

const PoolSummary = ({ data }) => {
    return (
        <div className="row d-flex flex-wrap mt-4">
            <div className="col-12 col-sm-6 col-md-4 d-flex flex-wrap pb-3 pb-md-0">
                <TradeFiOverviewCard
                    name="Active Loans"
                    value={data?.active_loans || "0%"}
                    value2={data?.active_loans_percentage || "0"}
                    variant="main"
                />
            </div>
            <div className="col-12 col-sm-6 col-md-4 d-flex flex-wrap pb-3 pb-md-0">
                <TradeFiOverviewCard
                    name="Total Loss Rate"
                    value={data?.total_loss_rate || "$0"}
                    value2={data?.total_loss_percentage || "0"}
                    variant="main"
                />
            </div>
            <div className="col-12 col-sm-6 col-md-4 d-flex flex-wrap pb-3 pb-md-0">
                <TradeFiOverviewCard
                    name="Total Loans Repaid"
                    value={data?.total_loans_repaid || "0%"}
                    value2={data?.total_loans_percentage || "0"}
                    variant="main"
                />
            </div>
        </div>
    );
};

export default PoolSummary;
