import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReauth } from './baseQueries';

export const fileManagementAPI = createApi({
    reducerPath: 'fileManagementAPI',
    baseQuery: baseQueryWithReauth,
    tagTypes: ['File'],
    endpoints: (builder) => ({
        uploadFile: builder.mutation({
            query: ({ file }) => {
                return {
                    url: `/api/files/`,
                    method: 'POST',
                    body: file,
                };
            },
            invalidatesTags: ['File'],
        }),
        downloadFile: builder.query({
            query: ({ fileId }) => ({
                url: `/api/files/${fileId}/`,
                method: 'GET',
            }),
        }),
    }),
});


export const { useUploadFileMutation, useDownloadFileQuery } = fileManagementAPI;