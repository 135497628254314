import React from "react";
import "./card.scss";

export default function TradeFiOverviewCard({ name, value, value2, style, variant }) {
    const cardStyle = variant === "main" ? "transparent-card" : "dashboard-card";

    const value2Class = parseFloat(value2) >= 0 ? "positive-percentage" : "negative-percentage";
    const formattedValue2 = parseFloat(value2) >= 0 ? `+${value2}%` : `${value2}%`;

    return (
        <div className={`card w-100 ${cardStyle}`} style={style}>
            <div className="card-body">
                <div className="card-title">
                    <div>
                        <h6 className="m-3 card-subtitle name-text mb-2">
                            {name}
                        </h6>
                        <h2>
                            <span className="p-3 number-text">{value}</span>
                        </h2>
                        <h2>
                            <span className={`p-3 number-text fs-5 ${value2Class}`}>{formattedValue2}</span>
                        </h2>
                    </div>
                </div>
            </div>
        </div>
    );
}
