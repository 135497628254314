// Desc: Configuration tab for the project page

import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import Tab from "../../../components/Tab/Tab";
import Typography from "../../../components/Typography/Typography";
import { useModal } from "../../../hooks/modalsHooks";
import AssetTab from "./Assets/AssetTab";
import NodesTab from "./Nodes/NodesTab";
import {
    useGetAssetsTypeQuery,
    useGetNodesListQuery,
} from "../../../services/supplyChainService";
import ProjectTabLoadingSkeleton from "../../../components/LoadingSkeleton/ProjectTabLoadingSkeleton";
import { useGetProjectDetailQuery } from "../../../services/projectService";

export default function ConfigureSupplyChainTraceabilityProject() {
    const navigate = useNavigate();
    const { showModal } = useModal();
    const handleEditNode = async () => {
        showModal({
            modalType: "EditModal",
        });
    };
    const { id } = useParams();
    const { data: project, error, isLoading } = useGetProjectDetailQuery(id);

    const handleCreateNode = async () => {
        navigate(`/overview/${id}/addNode`);
    };

    const handleCreateAsset = async () => {
        navigate(`/overview/${id}/addAsset`);
    };

    const { data: assetTypesResponse, isLoading: isLoadingAssetTypes } =
        useGetAssetsTypeQuery(id, { skip: !id });

    const { data: nodesResponse, isLoading: isLoadingNodes } =
        useGetNodesListQuery(id, { skip: !id });

    const nodesList = nodesResponse?.data || [];
    const assetTypesData = assetTypesResponse?.data || [];

    const nodeData = nodesList.map((node) => ({
        nodeTitle: node.name,
        location: node.location,
        asset: node.chain,
        createdBy: node.created_by,
        createdByAvatar: "/images/avatar8.svg",
        nodeUsers:
            node.node_users.length !== 0
                ? node.node_Users
                : "James, Peter, Joe",
        nodeUsersAvatar: [
            "/images/avatar5.svg",
            "/images/avatar2.svg",
            "/images/avatar3.svg",
        ],
        createdDate: new Date(node.created_at).toLocaleDateString("en-US", {
            day: "numeric",
            month: "long",
            year: "numeric",
        }),
        action: "--",
    }));

    if (isLoadingAssetTypes || isLoadingNodes) {
        return <ProjectTabLoadingSkeleton />;
    }

    return (
        <div className="row d-flex flex-wrap">
            <div className="col-12 col-md-12">
                <div className="d-flex align-items-center flex-column flex-md-row my-2">
                    <Typography variant="h3">Configuration</Typography>
                </div>
                <Tab
                    tabsData={[
                        {
                            name: "Nodes",
                            component: (
                                <NodesTab
                                    nodeData={nodeData}
                                    editOnclick={handleEditNode}
                                />
                            ),
                        },
                        {
                            name: "Record Types",
                            component: <AssetTab assetData={assetTypesData} />,
                        },
                    ]}
                    tabType="pills"
                    nodeData={nodeData}
                    assetData={assetTypesData}
                    isConfigureTab={true}
                    addAssetOnClick={handleCreateAsset}
                    addNodeOnClick={handleCreateNode}
                    defaultTab={0}
                />
            </div>
        </div>
    );
}
