import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReauth } from './baseQueries';


export const supplyChainAPI = createApi({
    reducerPath: 'supplyChainAPI',
    baseQuery: baseQueryWithReauth,
    tagTypes: ['Asset', 'AssetType', 'Node'],
    endpoints: (builder) => ({
        getAssetsType: builder.query({
            query: (id) => `/api/templates/supply_chain/${id}/asset_types/`,
            providesTags: ['AssetType']
        }),
        getOneAssetsType: builder.query({
            query: ({ id, assetTypeId }) => `/api/templates/supply_chain/${id}/asset_types/${assetTypeId}/`,
            providesTags: ['AssetType']
        }),
        createAssetType: builder.mutation({
            query: ({ id, asset_type_name }) => ({
                url: `/api/templates/supply_chain/${id}/asset_types/`,
                method: 'POST',
                body: {
                    asset_type_name,
                },
            })
        }),
        getAssetsList: builder.query({
            query: ({ projectId, nodeId }) => `/api/templates/supply_chain/${projectId}/assets/${nodeId ? `?node=${nodeId}` : ''}`,
            providesTags: ['Asset']
        }),
        getNodesList: builder.query({
            query: (id) => `/api/templates/supply_chain/${id}/nodes/`,
            providesTags: ['Node']
        }),
        createNode: builder.mutation({
            query: ({ id, name, description, location, promo_image }) => ({
                url: `/api/templates/supply_chain/${id}/nodes/`,
                method: `POST`,
                body: {
                    name,
                    description,
                    location,
                    promo_image
                }
            }),
            invalidatesTags: ['Node']
        }),
        createAsset: builder.mutation({
            query: ({ id, asset_id, asset_type, node, dependencies, form_data }) => ({
                url: `/api/templates/supply_chain/${id}/assets/`,
                method: 'POST',
                body: {
                    asset_id,
                    asset_type,
                    node,
                    dependencies,
                    form_data
                },
            }),
            invalidatesTags: ['Asset']
        }),
        getTraceAsset: builder.query({
            query: ({ id, asset_id }) => `/api/templates/supply_chain/${id}/assets/${asset_id}/trace/`
        }),
        confirmAsset: builder.mutation({
            query: ({ id, asset_id, transaction_hash, token_id = null }) => ({
                url: `/api/templates/supply_chain/${id}/assets/${asset_id}/confirm/`,
                method: 'PUT',
                body: {
                    transaction_hash,
                    token_id
                },
            }),
            invalidatesTags: ['Asset']
        }),
        updateAssetType: builder.mutation({
            query: ({ id, assetTypeId, form_config, dependency_config }) => ({
                url: `/api/templates/supply_chain/${id}/asset_types/${assetTypeId}/`,
                method: 'PUT',
                body: {
                    form_config,
                    dependency_config
                },
            }),
            invalidatesTags: ['AssetType']
        }),
        addTransfer: builder.mutation({
            query: ({ id, receiver, from_node, to_node, transfer_content }) => ({
                url: `/api/templates/supply_chain/${id}/asset_transfers/`,
                method: 'POST',
                body: {
                    receiver,
                    from_node,
                    to_node,
                    transfer_content

                },
            }),
            invalidatesTags: ['Asset']
        }),
        getAllTransfer: builder.query({
            query: (id) => `/api/templates/supply_chain/${id}/asset_transfers/`
        })


    }
    )
});

export const {
    useGetAssetsTypeQuery, useCreateAssetTypeMutation, useGetAssetsListQuery,
    useGetNodesListQuery, useCreateNodeMutation, useCreateAssetMutation,
    useLazyGetTraceAssetQuery, useConfirmAssetMutation, useGetOneAssetsTypeQuery,
    useUpdateAssetTypeMutation, useAddTransferMutation, useGetAllTransferQuery
} = supplyChainAPI;