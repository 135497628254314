// desc: Records Tab for the project page

import React from "react";
import { BasicTable } from "../../../components/tables";
import Typography from "../../../components/Typography/Typography";
import Button from "../../../components/Button/Button";
import { Icon } from "../../../components/Icon";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import {
    useGetAssetsListQuery,
    useGetAssetsTypeQuery,
} from "../../../services/supplyChainService";
import { useModal } from "../../../hooks/modalsHooks";
import ProjectTabLoadingSkeleton from "../../../components/LoadingSkeleton/ProjectTabLoadingSkeleton";
import { useGetProjectDetailQuery } from "../../../services/projectService";

export default function RecordTab({ tableData }) {
    const navigate = useNavigate();
    const { id } = useParams();
    const { data: project, error, isLoading } = useGetProjectDetailQuery(id);

    const { data: assetsResponse, isLoading: isLoadingAssets } =
        useGetAssetsListQuery({ projectId: id, skip: !id });

    const { data: assetTypesResponse, isLoading: isLoadingAssetTypes } =
        useGetAssetsTypeQuery(id, { skip: !id });

    const assetsList = assetsResponse?.data;
    const assetTypesData = assetTypesResponse?.data;
    const assetData =
        assetsList?.map((asset) => {
            return {
                assetId: asset.asset_id,
                node: asset.node ? asset.node.name : "N/A", // Check if asset.node exists before trying to access asset.node.name
                assetType: asset.asset_type.type_name,
                createdBy: asset.created_by.first_name,
                createdByAvatar: "/images/avatar.svg",
                createdAt: new Date(asset.created_at).toLocaleDateString(
                    "en-US",
                    {
                        day: "numeric",
                        month: "long",
                        year: "numeric",
                    }
                ),
                action: "--",
            };
        }) || [];

    const { showModal } = useModal();

    const handleAddRecords = () => {
        navigate(`/overview/${id}/addRecord`);
    };

    const handleTraceClick = (assetId) => {
        showModal({
            modalType: "TraceAssetModal",
            modalProps: {
                assetLink: `/app/${id}/trace/${assetId}`,
            },
        });
    };

    if (isLoadingAssetTypes || isLoadingAssets) {
        return <ProjectTabLoadingSkeleton />;
    }

    return (
        <div className="row">
            <div className="col-12 col-md-12">
                <div className="d-flex flex-column flex-sm-row justify-content-md-start justify-content-center align-items-center pt-3 pb-2 mb-3">
                    <div className="d-flex align-items-center flex-column flex-sm-row">
                        <Typography variant="h3">Records</Typography>
                        <div className="ps-md-3 ps-0 pb-1 subtitle">
                            {assetData.length} total records
                        </div>
                    </div>
                    <div className="me-0 mb-2 mb-sm-0 p-md-0 p-4 pt-3 pe-md-1 ms-sm-auto d-flex justify-content-center justify-content-sm-start">
                        <Button
                            className="text-truncate"
                            type="button"
                            variant="primary"
                            fullWidth={false}
                            onClick={handleAddRecords}
                        >
                            Add records
                        </Button>
                    </div>
                </div>

                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center mt-3 pb-2 mb-1">
                    <div className="w-sm-auto my-md-0 d-flex">
                        {/* <Dropdown
                            buttonLabel="All Nodes"
                            items={[
                                {
                                    label: "Action",
                                    href: "#",
                                },
                                {
                                    label: "Another action",
                                    href: "#",
                                },
                                {
                                    label: "Something else here",
                                    href: "#",
                                },
                            ]}
                        /> */}
                    </div>
                </div>

                <BasicTable
                    data={assetData}
                    columns={[
                        {
                            header: "Asset",
                            accessor: "assetId",
                        },
                        {
                            header: "Node",
                            accessor: "node",
                        },
                        {
                            header: "Asset Type",
                            accessor: "assetType",
                        },
                        {
                            header: "Created by",
                            accessor: "createdBy",
                            Cell: ({ row }) => (
                                <div className="d-flex align-items-center">
                                    <img
                                        src={row.original.createdByAvatar}
                                        alt={row.original.createdBy}
                                        className="rounded-circle me-2"
                                        style={{
                                            width: "20px",
                                            height: "20px",
                                        }} // Adjust size as needed
                                    />
                                    {row.original.createdBy}
                                </div>
                            ),
                        },
                        {
                            header: "Created date",
                            accessor: "createdAt",
                            Cell: ({ cell: { value } }) => {
                                const dateObject = new Date(value);
                                const formattedDate = dateObject
                                    .toLocaleDateString("en-US")
                                    .split("/")
                                    .join("-");
                                return <div>{formattedDate}</div>;
                            },
                        },
                        {
                            header: "",
                            accessor: "action",
                            Cell: ({ row }) => (
                                <div className="d-flex flex-row justify-content-end">
                                    <Icon
                                        src="/icons/routing.svg"
                                        className="mx-2"
                                        height={15}
                                        width={15}
                                        onClick={() => {
                                            handleTraceClick(
                                                row.original.assetId
                                            );
                                        }}
                                    />
                                    <Icon
                                        src="/icons/edit.svg"
                                        className="mx-2"
                                        height={15}
                                        width={15}
                                        onClick={() => {}}
                                    />
                                    <Icon
                                        src="/icons/archive.svg"
                                        height={15}
                                        width={15}
                                        className="mx-2"
                                        onClick={() => {}}
                                    />
                                </div>
                            ),
                        },
                    ]}
                    isSortable={false}
                    isPaginated={assetData?.length > 5}
                    pageSize={5}
                    currentPage={0}
                    showCheckBox={true}
                />
            </div>
        </div>
    );
}
