import React from "react";
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
    LabelList,
} from "recharts";

const renderCustomizedLabel = (props) => {
    const { x, y, width } = props;
    const strokeWidth = 3;

    return (
        <g>
            <line
                x1={x}
                y1={y}
                x2={x + width}
                y2={y}
                stroke="#6b8fa8"
                strokeWidth={strokeWidth}
            />
        </g>
    );
};

const BarChartComponent = ({ data, dataKey }) => {
    const [opacity, setOpacity] = React.useState({
        [dataKey]: 1,
    });

    const handleMouseEnter = (o) => {
        const { dataKey } = o;

        setOpacity((op) => ({ ...op, [dataKey]: 0.5 }));
    };

    const handleMouseLeave = (o) => {
        const { dataKey } = o;

        setOpacity((op) => ({ ...op, [dataKey]: 1 }));
    };

    return (
        <div style={{ width: "100%" }}>
            <ResponsiveContainer width="100%" height={300}>
                <BarChart
                    width={500}
                    height={300}
                    data={data}
                    margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                    }}
                >
                    <CartesianGrid horizontal={false} vertical={false} />
                    <XAxis
                        dataKey="month"
                        axisLine={false}
                        tickLine={false}
                        tick={{
                            fill: "#4F7A96",
                            fontWeight: "bold",
                            fontSize: "15px",
                            fontFamily: "Arial",
                            stroke: "none",
                        }}
                    />
                    <YAxis hide={true} />
                    <Tooltip cursor={{ fill: "transparent" }} />
                    <Legend
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                    />
                    <Bar
                        dataKey={dataKey}
                        fill="#a3b9cc"
                        fillOpacity={opacity[dataKey]}
                        barSize={80}
                    >
                        <LabelList
                            dataKey={dataKey}
                            content={renderCustomizedLabel}
                        />
                    </Bar>
                </BarChart>
            </ResponsiveContainer>
        </div>
    );
};

export default BarChartComponent;
